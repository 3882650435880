import React from 'react';
import Container from 'web/components/Container';
import LazyImg from 'web/components/LazyImg';
import useSanityImageUrlBuilder from 'web/hooks/useSanityImageUrlBuilder';
import themeClasses from 'web/styles/themeClasses.css';
import { benefitsFeatureGrid, benefitsFeatureItem, benefitsHeading, benefitsText } from './landingPage.css';
import { LandingPageBenefits } from './types';

const Benefits = (benefits: LandingPageBenefits) => {
  const imgUrl = useSanityImageUrlBuilder();
  return (
    <section
      className={themeClasses({
        paddingTop: 20,
        paddingBottom: 32,
      })}
      style={{ backgroundColor: '#fff4e9' }}
    >
      <Container>
        <h1 className={benefitsHeading}>{benefits.heading}</h1>
        <p className={benefitsText}>{benefits.text}</p>
        <div className={benefitsFeatureGrid}>
          {benefits.features.map((feature) => (
            <div className={benefitsFeatureItem} key={feature._key}>
              <h2
                className={themeClasses({
                  margin: 0,
                })}
              >
                {feature.heading}
              </h2>
              <p
                className={themeClasses({
                  margin: 0,
                })}
              >
                {feature.text}
              </p>
              <LazyImg
                src={imgUrl(feature.image).width(520).url()}
                alt={feature.image.alt || ''}
                width={520}
                height={520 / feature.image.metadata.dimensions.aspectRatio}
                className={themeClasses({
                  width: '100%',
                  height: 'auto',
                  borderRadius: 'md',
                  boxShadow: 'md',
                })}
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Benefits;
