import React from 'react';
import Container from 'web/components/Container';
import { LinkStyled } from 'web/components/elements';
import LazyImg from 'web/components/LazyImg';
import useSanityImageUrlBuilder from 'web/hooks/useSanityImageUrlBuilder';
import themeClasses from 'web/styles/themeClasses.css';
import { BigButton } from './common';
import {
  heroGridCenter,
  heroSection,
  heroTextCenter,
  heroTitleCenter,
  heroTools,
  heroToolsImage,
} from './landingPage.css';
import { LandingPageHero } from './types';

const Hero = (hero: LandingPageHero) => {
  const imgUrl = useSanityImageUrlBuilder();
  return (
    <section className={heroSection}>
      <Container>
        <div className={heroGridCenter}>
          <h1 className={heroTitleCenter}>{hero.heading}</h1>
          <div className={heroTextCenter}>
            <p className={themeClasses({ marginTop: 0 })}>{hero.text}</p>
            {hero.pageLinks && (
              <p className={themeClasses({ marginBottom: 0 })}>
                Join{' '}
                {hero.pageLinks.map((link, idx) => (
                  <>
                    <LinkStyled to={link.url} target="_blank" key={idx}>
                      {link.text}
                    </LinkStyled>
                    {idx < hero.pageLinks.length - 1 && <>, </>}
                  </>
                ))}
                , and others today.
              </p>
            )}
          </div>
          <BigButton to="/get-started">{hero.buttonText}</BigButton>
          {hero.toolsText && <p className={themeClasses({ marginTop: 16 })}>{hero.toolsText}</p>}
          {hero.tools && (
            <div className={heroTools}>
              {hero.tools.map((tool) => (
                <LazyImg
                  key={tool._key}
                  src={imgUrl(tool.image).height(80).url()}
                  alt={tool.image.alt}
                  height={80}
                  width={80 * tool.image.metadata.dimensions.aspectRatio}
                  className={heroToolsImage}
                />
              ))}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default Hero;
