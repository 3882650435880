import React from 'react';
import Container from 'web/components/Container';
import { LinkStyled } from 'web/components/elements';
import LazyImg from 'web/components/LazyImg';
import useSanityImageUrlBuilder from 'web/hooks/useSanityImageUrlBuilder';
import themeClasses from 'web/styles/themeClasses.css';
import joinClassNames from 'web/utils/joinClassNames';
import {
  centerTextMixin,
  sectionHeading,
  startContainer,
  startStepsGrid,
  startStepsItem,
  startStepsItemImage,
  startTitle,
} from './landingPage.css';
import { LandingPageSteps } from './types';

const Steps = (start: LandingPageSteps) => {
  const imgUrl = useSanityImageUrlBuilder();
  return (
    <section
      className={themeClasses({
        paddingTop: 16,
        paddingBottom: 20,
      })}
    >
      <Container>
        <div className={startContainer}>
          <h2 className={joinClassNames(sectionHeading, startTitle)}>{start.heading}</h2>
          <p className={centerTextMixin}>{start.text}</p>
          <div className={startStepsGrid}>
            {start.steps.map((step) => (
              <div className={startStepsItem} key={step._key}>
                <h3 className={themeClasses({ margin: 0 })}>
                  {step.heading.startsWith('Sign up') ? (
                    <>
                      <LinkStyled to="/get-started">Sign up</LinkStyled> {step.heading.substring('Sign up'.length)}
                    </>
                  ) : (
                    step.heading
                  )}
                </h3>
                <p>{step.text}</p>
                <div className={startStepsItemImage}>
                  <LazyImg
                    src={imgUrl(step.image).width(320).url()}
                    alt={step.image.alt || ''}
                    width={320}
                    height={320 / step.image.metadata.dimensions.aspectRatio}
                    className={themeClasses({
                      maxWidth: '100%',
                      height: 'auto',
                      display: 'block',
                    })}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Steps;
