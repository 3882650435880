import React from 'react';
import Container from 'web/components/Container';
import LazyImg from 'web/components/LazyImg';
import useSanityImageUrlBuilder from 'web/hooks/useSanityImageUrlBuilder';
import themeClasses from 'web/styles/themeClasses.css';
import { BigButton } from './common';
import { heroGridSide, heroImageSide, heroSection, heroTextSide, heroTitleSide } from './landingPage.css';
import { LandingPageHeroWithImage } from './types';

const Hero = (hero: LandingPageHeroWithImage) => {
  const imgUrl = useSanityImageUrlBuilder();
  return (
    <section className={heroSection}>
      <Container>
        <div className={heroGridSide}>
          <div>
            <h1 className={heroTitleSide}>{hero.heading}</h1>
            <div className={heroTextSide}>
              <p className={themeClasses({ marginY: 0 })}>{hero.text}</p>
            </div>
            <BigButton to="/get-started">{hero.buttonText}</BigButton>
          </div>
          <div>
            <LazyImg
              src={imgUrl(hero.image).width(540).url()}
              alt={hero.image.alt || 'Hero image'}
              width={540}
              height={540 / hero.image.metadata.dimensions.aspectRatio}
              className={heroImageSide}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
