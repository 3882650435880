import React from 'react';
import Container from 'web/components/Container';
import { LinkStyled } from 'web/components/elements';
import LazyImg from 'web/components/LazyImg';
import useSanityImageUrlBuilder from 'web/hooks/useSanityImageUrlBuilder';
import themeClasses from 'web/styles/themeClasses.css';
import {
  testimonialCaption,
  testimonialGrid,
  testimonialHeading,
  testimonialImageCard,
  testimonialQuotationMark,
  testimonialQuote,
  videoArea,
} from './testimonial.css';
import { LandingPageTestimonial } from './types';

const Testimonail = (testimonials: LandingPageTestimonial) => {
  const imgUrl = useSanityImageUrlBuilder();
  return (
    <section
      className={themeClasses({
        paddingTop: 16,
        paddingBottom: 20,
      })}
    >
      <div className={testimonialGrid}>
        <Container style={{ gridArea: 'container' }} />
        <div style={{ backgroundColor: '#ECF7FA', gridArea: 'top / 1 / middle / -1' }} />
        <div style={{ gridArea: 'middle / 1 / bottom / -1' }} />
        <div style={{ gridArea: 'heading', alignSelf: 'center' }}>
          <h2 className={testimonialHeading}>{testimonials.heading}</h2>
          <p>{testimonials.text}</p>
        </div>
        <div style={{ gridArea: 'quotes' }} className={themeClasses({ position: 'relative' })}>
          <div className={testimonialQuotationMark}>”</div>
          <div
            className={themeClasses({
              position: 'relative',
              marginLeft: {
                md: 20,
              },
              marginTop: {
                md: 20,
              },
            })}
          >
            {testimonials.quotes.map((quote) => (
              <div className={testimonialQuote} key={quote}>
                “{quote}”
              </div>
            ))}
            <p className={testimonialCaption}>
              {testimonials.caption}
              {testimonials.captionUrl && (
                <>
                  <br />
                  <LinkStyled to={testimonials.captionUrl} target="_blank">
                    introwise.com{testimonials.captionUrl}
                  </LinkStyled>
                </>
              )}
            </p>
          </div>
        </div>
        <div style={{ gridArea: 'video' }} className={videoArea}>
          <div className={testimonialImageCard}>
            <LazyImg
              src={imgUrl(testimonials.image).width(360).url()}
              alt={testimonials.image.alt || ''}
              width={360}
              height={360 / testimonials.image.metadata.dimensions.aspectRatio}
              className={themeClasses({
                width: '100%',
                height: 'auto',
              })}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonail;
