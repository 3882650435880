import React from 'react';
import { Navigate } from 'react-router';
import AppFooterLarge from 'web/components/AppFooterLarge';
import AppHeaderMarketing, { AppHeaderStickyContainer } from 'web/components/AppHeaderMarketing';
import CenterPageContainer from 'web/components/CenterPageContainer';
import Meta from 'web/components/Meta';
import PageLoadingFallback from 'web/components/PageLoadingFallback';
import ScreenTracker from 'web/components/ScreenTracker';
import ScrollToTopOnMount from 'web/components/ScrollToTopOnMount';
import useInitialRouteData from 'web/hooks/useInitialRouteData';
import Benefits from './Benefits';
import Cta from './Cta';
import Features from './Features';
import Hero from './Hero';
import HeroWithImage from './HeroWithImage';
import Nudge from './Nudge';
import Pricing from './Pricing';
import Steps from './Steps';
import Testimonail from './Testimonial';
import { LandingPage as LandingPageType, LandingPageBlock } from './types';
import useCachedPageQuery from './useCachedPageQuery';

const renderBlock = (block: LandingPageBlock) => {
  switch (block._type) {
    case 'hero':
      return <Hero {...block} />;
    case 'heroWithImage':
      return <HeroWithImage {...block} />;
    case 'benefits':
      return <Benefits {...block} />;
    case 'nudge':
      return <Nudge {...block} />;
    case 'steps':
      return <Steps {...block} />;
    case 'pricing':
      return <Pricing {...block} />;
    case 'testimonial':
      return <Testimonail {...block} />;
    case 'features':
      return <Features {...block} />;
    case 'cta':
      return <Cta {...block} />;
    default:
      return <></>;
  }
};

const LandingPage = ({ slug, documentType }: { slug: string; documentType: string }) => {
  const initialPage = useInitialRouteData<LandingPageType>();
  const [page, loading, error] = useCachedPageQuery(documentType, slug, initialPage);
  return (
    <>
      <ScreenTracker screenName="LandingPage" />
      <>
        <AppHeaderStickyContainer>
          <AppHeaderMarketing logoLook="gradient" />
        </AppHeaderStickyContainer>
        {loading && <PageLoadingFallback />}
        {!loading && error && (
          <CenterPageContainer>
            <div style={{ textAlign: 'center' }}>
              <h1>Error</h1>
              <p>Something went wrong. Please try again later.</p>
            </div>
          </CenterPageContainer>
        )}
        {!loading && !error && !page && <Navigate to="/" />}
        {!loading && !error && page && (
          <>
            <ScrollToTopOnMount key={page._id} />
            <Meta title={page.title} description={page.description} image={page.metaImage} />
            {page.blocks?.map((block) => (
              <React.Fragment key={block._key}>{renderBlock(block)}</React.Fragment>
            ))}
            <AppFooterLarge />
          </>
        )}
      </>
    </>
  );
};

export default LandingPage;
