type PricingPlanBase = {
  name: string;
  description: string;
  applicationFeePercentage: number;
  monthlyCallsParticipantSeconds: number;
  features: introwise.PaidFeaturesFlags;
};

type PricingPlanSubscription = PricingPlanBase & {
  type: 'subscription';
  prices: {
    [priceId: string]: {
      currency: string;
      amount: number;
      billing: 'annual' | 'monthly';
      trialPeriodDays: number;
      extendedTrial?: boolean;
    };
  };
};

type PricingPlanCommission = PricingPlanBase & {
  type: 'commission';
};

type PricingPlanSubscriptionId = 'subscription' | 'growth' | 'growth_1_5';

const pricingPlansSubscription: {
  [pricingPlanId in PricingPlanSubscriptionId]: PricingPlanSubscription;
} = {
  subscription: {
    name: 'Pro',
    type: 'subscription',
    description: 'Unlimited usage and no Introwise commission.',
    applicationFeePercentage: 0,
    monthlyCallsParticipantSeconds: 6000 * 60,
    features: {
      multipleAvailabilities: true,
      multipleSchedulingRules: true,
      branding: true,
      customCallBackgrounds: true,
      customDomain: true,
      discounts: true,
      stripePaymentMethods: true,
      increasedCalendarsLimit: true,
      recording: true,
      cancellationRules: true,
      timeLimitedPackages: true,
      multipleWorkflows: true,
      paymentPlans: true,
    },
    prices: {
      annual_usd_37200: {
        currency: 'usd',
        amount: 37200,
        billing: 'annual',
        trialPeriodDays: 14,
      },
      annual_usd_39900: {
        currency: 'usd',
        amount: 39900,
        billing: 'annual',
        trialPeriodDays: 14,
      },
      monthly_usd_3900: {
        currency: 'usd',
        amount: 3900,
        billing: 'monthly',
        trialPeriodDays: 14,
      },
    },
  },
  growth_1_5: {
    name: 'Growth',
    type: 'subscription',
    description: 'Growth features and just 1.5% Introwise commission.',
    applicationFeePercentage: 0.015,
    monthlyCallsParticipantSeconds: 2000 * 60,
    features: {
      multipleAvailabilities: true,
      multipleSchedulingRules: true,
      branding: true,
      customCallBackgrounds: true,
      discounts: true,
      timeLimitedPackages: true,
      multipleWorkflows: true,
    },
    prices: {
      annual_usd_14400: {
        currency: 'usd',
        amount: 14400,
        billing: 'annual',
        trialPeriodDays: 14,
        extendedTrial: false,
      },
      monthly_usd_1500: {
        currency: 'usd',
        amount: 1500,
        billing: 'monthly',
        trialPeriodDays: 14,
        extendedTrial: false,
      },
    },
  },
  growth: {
    name: 'Growth',
    type: 'subscription',
    description: 'Growth features and 4.9% Introwise commission.',
    applicationFeePercentage: 0.049,
    monthlyCallsParticipantSeconds: 2000 * 60,
    features: {
      multipleAvailabilities: true,
      branding: true,
      customCallBackgrounds: true,
      discounts: true,
      timeLimitedPackages: true,
      multipleWorkflows: true,
      multipleSchedulingRules: true,
    },
    prices: {
      annual_usd_14400: {
        currency: 'usd',
        amount: 14400,
        billing: 'annual',
        trialPeriodDays: 14,
        extendedTrial: false,
      },
      monthly_usd_1500: {
        currency: 'usd',
        amount: 1500,
        billing: 'monthly',
        trialPeriodDays: 14,
        extendedTrial: false,
      },
    },
  },
};

type PricingPlanCommissionId = 'commission_9_8' | 'commission_2_5';

const pricingPlansFree: {
  [pricingPlanId in PricingPlanCommissionId]: PricingPlanCommission;
} = {
  commission_9_8: {
    name: 'Free 9.8%',
    type: 'commission',
    description: 'Free plan with 9.8% Introwise commission.',
    applicationFeePercentage: 0.098,
    monthlyCallsParticipantSeconds: 1000 * 60,
    features: {} as introwise.PaidFeaturesFlags,
  },
  commission_2_5: {
    name: 'Free 2.5%',
    type: 'commission',
    description: 'Free plan with 2.5% Introwise commission.',
    applicationFeePercentage: 0.025,
    monthlyCallsParticipantSeconds: 1000 * 60,
    features: {} as introwise.PaidFeaturesFlags,
  },
};

type PricingPlanLifetime = PricingPlanBase & {
  type: 'lifetime';
  featuresDescription: string[];
};

type PricingPlanLifetimeId = 'appsumo_growth' | 'appsumo_pro';

const pricingPlansAppSumo: {
  [pricingPlanId in PricingPlanLifetimeId]: PricingPlanLifetime;
} = {
  appsumo_growth: {
    name: 'AppSumo Growth',
    type: 'lifetime',
    description: 'All Growth features and no Introwise commission. You only pay payment processing fees.',
    featuresDescription: [],
    applicationFeePercentage: 0,
    monthlyCallsParticipantSeconds: 2000 * 60,
    features: {
      multipleAvailabilities: true,
      branding: true,
      discounts: true,
      customCallBackgrounds: true,
      timeLimitedPackages: true,
      multipleWorkflows: true,
      multipleSchedulingRules: true,
    },
  },
  appsumo_pro: {
    name: 'AppSumo Pro',
    type: 'lifetime',
    description: 'All Pro features and no Introwise commission. You only pay payment processing fees.',
    featuresDescription: [],
    applicationFeePercentage: 0,
    monthlyCallsParticipantSeconds: 6000 * 60,
    features: {
      multipleAvailabilities: true,
      branding: true,
      customDomain: true,
      discounts: true,
      stripePaymentMethods: true,
      customCallBackgrounds: true,
      increasedCalendarsLimit: true,
      recording: true,
      cancellationRules: true,
      timeLimitedPackages: true,
      multipleWorkflows: true,
      paymentPlans: true,
      multipleSchedulingRules: true,
    },
  },
};

const pricingPlans = {
  ...pricingPlansSubscription,
  ...pricingPlansFree,
  ...pricingPlansAppSumo,
};

type PricingPlanId = keyof typeof pricingPlans;

const defaultPricingPlanId: PricingPlanId = 'commission_2_5';

const growthPricingPlanId = 'growth_1_5';
const subscriptionPricingPlanId = 'subscription';

const subscriptionPricingPlansIds = [growthPricingPlanId, subscriptionPricingPlanId] as const;
type SubscriptionPricingPlanId = typeof subscriptionPricingPlansIds[number];
type SubscriptionPricingPriceId = string;
const subscriptionPricingPriceId: SubscriptionPricingPriceId = 'monthly_usd_3900';
const subscriptionPricingAnnualPriceId: SubscriptionPricingPriceId = 'annual_usd_37200';

const featuresUpgradePath: Required<{ [feature in keyof introwise.PaidFeaturesFlags]: PricingPlanId }> = {
  branding: growthPricingPlanId,
  customDomain: subscriptionPricingPlanId,
  discounts: growthPricingPlanId,
  stripePaymentMethods: subscriptionPricingPlanId,
  multipleAvailabilities: growthPricingPlanId,
  customCallBackgrounds: growthPricingPlanId,
  increasedCalendarsLimit: subscriptionPricingPlanId,
  recording: subscriptionPricingPlanId,
  cancellationRules: subscriptionPricingPlanId,
  timeLimitedPackages: growthPricingPlanId,
  multipleWorkflows: growthPricingPlanId,
  paymentPlans: subscriptionPricingPlanId,
  multipleSchedulingRules: growthPricingPlanId,
};

const paidFeaturesNames: {
  [feature in keyof Required<introwise.PaidFeaturesFlags>]: string;
} = {
  branding: 'Custom branding',
  customDomain: 'Custom domain',
  paymentPlans: 'Payment plans',
  discounts: 'Discounts',
  stripePaymentMethods: 'Local payment methods',
  increasedCalendarsLimit: 'Four connected calendars',
  multipleAvailabilities: 'Multiple availabilities',
  multipleSchedulingRules: 'Multiple scheduling rules',
  cancellationRules: 'Cancellation and rescheduling rules',
  customCallBackgrounds: 'Custom call backgrounds',
  recording: 'Calls recording and live streaming',
  timeLimitedPackages: 'Time-limited packages',
  multipleWorkflows: 'Multiple workflows',
};

const isValidPricingPlan = (pricingPlanId: string): pricingPlanId is PricingPlanId =>
  Object.keys(pricingPlans).includes(pricingPlanId);

const isFeatureIncluded = (pricingPlanId: string, feature: keyof introwise.PaidFeaturesFlags): boolean =>
  isValidPricingPlan(pricingPlanId) && pricingPlans[pricingPlanId].features[feature];

export {
  pricingPlans,
  pricingPlansSubscription,
  defaultPricingPlanId,
  growthPricingPlanId,
  subscriptionPricingPlanId,
  subscriptionPricingPlansIds,
  subscriptionPricingPriceId,
  PricingPlanId,
  SubscriptionPricingPlanId,
  SubscriptionPricingPriceId,
  PricingPlanLifetime,
  PricingPlanSubscription,
  PricingPlanCommission,
  subscriptionPricingAnnualPriceId,
  featuresUpgradePath,
  paidFeaturesNames,
  isValidPricingPlan,
  isFeatureIncluded,
};
