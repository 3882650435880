import React from 'react';
import Container from 'web/components/Container';
import themeClasses from 'web/styles/themeClasses.css';
import joinClassNames from 'web/utils/joinClassNames';
import { BigButton } from './common';
import { centerTextMixin, centerTextWidthMixin, ctaSection, sectionHeading } from './landingPage.css';
import { LandingPageCta } from './types';

const Cta = (cta: LandingPageCta) => (
  <section className={ctaSection}>
    <Container>
      <h2 className={joinClassNames(sectionHeading, centerTextMixin)}>{cta.heading}</h2>
      <p
        className={joinClassNames(
          centerTextMixin,
          centerTextWidthMixin,
          themeClasses({
            marginTop: 8,
            marginBottom: 11,
          }),
        )}
      >
        {cta.text}
      </p>
      <div className={themeClasses({ textAlign: 'center' })}>
        <BigButton to="/get-started">{cta.buttonText}</BigButton>
      </div>
    </Container>
  </section>
);

export default Cta;
