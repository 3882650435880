import React from 'react';
import Container from 'web/components/Container';
import LazyImg from 'web/components/LazyImg';
import useSanityImageUrlBuilder from 'web/hooks/useSanityImageUrlBuilder';
import themeClasses from 'web/styles/themeClasses.css';
import joinClassNames from 'web/utils/joinClassNames';
import { nudgeGrid, nudgeImage, nudgeText, sectionHeading } from './landingPage.css';
import { LandingPageNudge } from './types';

const Nudge = (nudge: LandingPageNudge) => {
  const imgUrl = useSanityImageUrlBuilder();
  return (
    <section className={themeClasses({ paddingY: 20 })}>
      <Container>
        <div className={nudgeGrid({ mirrored: !!nudge.mirrored })}>
          <div className={nudgeText}>
            <h2 className={joinClassNames(sectionHeading, themeClasses({ marginTop: 0 }))}>{nudge.heading}</h2>
            <p className={themeClasses({ marginTop: 10 })}>{nudge.text}</p>
          </div>
          <LazyImg
            src={imgUrl(nudge.image).width(600).url()}
            alt={nudge.image.alt || ''}
            width={600}
            height={600 / nudge.image.metadata.dimensions.aspectRatio}
            className={joinClassNames(nudgeImage, themeClasses({ width: '100%', height: 'auto' }))}
          />
        </div>
      </Container>
    </section>
  );
};

export default Nudge;
