import React from 'react';
import { AnchorStyled, LinkButton } from 'web/components/elements';
import themeClasses from 'web/styles/themeClasses.css';
import joinClassNames from 'web/utils/joinClassNames';
import { pricingPlans, subscriptionPricingPlanId, subscriptionPricingPriceId } from 'web/utils/pricingPlans';
import sc from '../styled';
import {
  centerTextMixin,
  pricingFeaturesList,
  pricingFeaturesListItem,
  pricingGrid,
  pricingPlanCard,
  pricingPlanPrice,
  pricingPlanSubtitle,
  pricingPlanTitle,
  pricingStripeLink,
  sectionHeading,
} from './landingPage.css';
import { LandingPagePricing } from './types';

const PricingPlanTitle = sc.h2(pricingPlanTitle);
const PricingPlanPrice = sc.div(pricingPlanPrice);
const PricingPlanSubtitle = sc.div(pricingPlanSubtitle);
const PricingFeaturesList = sc.ul(pricingFeaturesList);
const PricingFeaturesListItem = sc.li(pricingFeaturesListItem);

const StripeLink = sc(AnchorStyled)(pricingStripeLink);
const PricingPlanCard = sc.div(pricingPlanCard);

const planPayAsYouGoId = 'commission_2_5';
const planPayAsYouGo = pricingPlans[planPayAsYouGoId];
const planSubscription = pricingPlans[subscriptionPricingPlanId];
const priceSubscription = planSubscription.prices[subscriptionPricingPriceId];

const Pricing = (pricing: LandingPagePricing) => (
  <section
    className={themeClasses({
      paddingY: 20,
    })}
  >
    <h2 className={joinClassNames(centerTextMixin, sectionHeading, themeClasses({ margin: 0 }))}>{pricing.heading}</h2>
    <p className={centerTextMixin}>{pricing.text}</p>
    <div className={pricingGrid}>
      <PricingPlanCard>
        <PricingPlanTitle>Pay-as-you-go</PricingPlanTitle>
        <PricingPlanPrice>Free</PricingPlanPrice>
        <PricingPlanSubtitle>
          {planPayAsYouGo.applicationFeePercentage * 100}% commission + payment processing
        </PricingPlanSubtitle>
        <PricingFeaturesList>
          {pricing.freeFeatures.map((feature, idx) => (
            <PricingFeaturesListItem key={feature}>
              {feature}
              {idx === pricing.freeFeatures.length - 1 && (
                <>
                  <br />{' '}
                  <StripeLink href="https://stripe.com/pricing" target="_blank" rel="noreferrer">
                    Learn more about Stripe fees
                  </StripeLink>
                </>
              )}
            </PricingFeaturesListItem>
          ))}
        </PricingFeaturesList>
        <div className={themeClasses({ marginTop: 4 })}>
          <LinkButton primary md to="/get-started?plan=free">
            Start for free
          </LinkButton>
        </div>
      </PricingPlanCard>
      <PricingPlanCard>
        <PricingPlanTitle>Subscription</PricingPlanTitle>
        <PricingPlanPrice>US${priceSubscription.amount / 100}</PricingPlanPrice>
        <PricingPlanSubtitle>per month + payment processing</PricingPlanSubtitle>
        <PricingFeaturesList>
          {pricing.subscriptionFeatures.map((feature, idx) => (
            <PricingFeaturesListItem key={idx}>
              {feature}
              {idx === pricing.subscriptionFeatures.length - 1 && (
                <>
                  <br />{' '}
                  <StripeLink href="https://stripe.com/pricing" target="_blank" rel="noreferrer">
                    Learn more about Stripe fees
                  </StripeLink>
                </>
              )}
            </PricingFeaturesListItem>
          ))}
        </PricingFeaturesList>
        <div className={themeClasses({ marginTop: 4 })}>
          <LinkButton primary md to="/get-started?plan=monthly">
            Start 14-day trial
          </LinkButton>
        </div>
      </PricingPlanCard>
    </div>
  </section>
);

export default Pricing;
