import { useEffect, useMemo } from 'react';
import useLoadingState from 'web/hooks/firebase/useLoadingState';
import useQueryCache from 'web/hooks/useQueryCache';
import useSanity from 'web/hooks/useSanity';
import { LandingPage } from './types';

const imageProjection = `'image': {
  "_type": @.image._type,
  "alt": @.image.alt,
  "asset": @.image.asset,
  "metadata": @.image.asset->metadata{
    dimensions,
  }
}`;

const query = `*[_type == $documentType && slug.current == $slug][0]{
  ...,
  'blocks': blocks[]{
    ...,
    _type == 'heroWithImage' || _type == 'nudge' || _type == 'testimonial' => {
      ${imageProjection}
    },
    _type == 'hero' => {
      'tools': tools[]{
        ...,
        ${imageProjection}
      }
    },
    _type == 'benefits' => {
      'features': features[]{
        ...,
        ${imageProjection}
      }
    },
    _type == 'steps' => {
      'steps': steps[]{
        ...,
        ${imageProjection}
      }
    },
  }
}`;

const usePageQuery = (documentType: string, slug: string) => {
  const { value, setValue, setError, error, loading } = useLoadingState<LandingPage, Error>();
  const sanity = useSanity();
  useEffect(() => {
    const fetch = async () => {
      try {
        const page = await sanity.fetch<LandingPage>(query, { slug, documentType });
        setValue(page);
      } catch (err) {
        setError(err);
      }
    };
    void fetch();
  }, [sanity, setError, setValue, slug, documentType]);
  return useMemo(() => [value, loading, error] as const, [error, loading, value]);
};

const useCachedPageQuery = (documentType: string, slug: string, initialData?: LandingPage) => {
  const result = usePageQuery(documentType, slug);
  return useQueryCache(`landingPage:${slug}`, result, initialData);
};

export default useCachedPageQuery;
