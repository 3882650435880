import React from 'react';
import Container from 'web/components/Container';
import useSanityImageUrlBuilder from 'web/hooks/useSanityImageUrlBuilder';
import themeClasses from 'web/styles/themeClasses.css';
import joinClassNames from 'web/utils/joinClassNames';
import {
  featuresGrid,
  featuresItemIcon,
  featuresItemIconCircle,
  featuresItemIconImage,
  featuresItemTitle,
  sectionHeading,
} from './landingPage.css';
import { LandingPageFeatures } from './types';

const Features = (features: LandingPageFeatures) => {
  const imgUrl = useSanityImageUrlBuilder();
  return (
    <section
      className={themeClasses({
        paddingTop: 16,
        paddingBottom: 32,
      })}
      style={{ backgroundColor: '#FCFAF5' }}
    >
      <Container>
        <h2 className={joinClassNames(sectionHeading, themeClasses({ marginTop: 0 }))}>{features.heading}</h2>
        <p>{features.text}</p>
        <div className={featuresGrid}>
          {features.features.map((feature) => (
            <div className={themeClasses({ marginTop: 14 })} key={feature._key}>
              <div className={featuresItemTitle}>
                <div className={featuresItemIcon}>
                  <div className={featuresItemIconCircle} style={{ backgroundColor: feature.color }} />
                  <div
                    className={featuresItemIconImage}
                    style={{ backgroundImage: `url('${imgUrl(feature.icon).url()}')` }}
                  />
                </div>
                <h3 className={themeClasses({ marginTop: 1.5 })}>{feature.heading}</h3>
              </div>
              <p>{feature.text}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Features;
